import React from 'react'
import './experience.css'


const Experience = () => {
  return (
    <div id='experience'>
      <div className='experience-container'>
        <div className='experience-title'>Experience</div>
        <div className='experience-info'>I’ve been lucky enough to wear a lot of hats in my career, from recruiting and office management, to sales operations and analytics. This has given me the chance to figure out what I love to do, and what I could do without.  I love solving difficult problems, working in a team and building relationships with my peers, and creating seamless experiences for clients.</div>
        <a href='https://drive.google.com/file/d/1tGMGJA75P6B5L_vNEYoGf4FDKoKxrh3P/view' target='_blank' className='resume'>View My Resume</a>
      </div>

    </div>
  )
}

export default Experience
