import React from 'react'
import './intro.css'

const Intro = () => {
  return (
    <div className='intro-container'>
      <div className='name-container'>
      <div className='name'>Nora</div>
      <div className='name'>Lashner</div>
      <div className='rectangle'></div>
      </div>
      <p className="intro-text">Hi! I’m a fullstack software engineer based in New York City.  After working on the go-to-market side of companies, I decided I wanted to help build products rather than sell them.  I love solving problems, desiging processes, and meeting new people. If you’re in New York and looking for a fullstack engineer to join your team, let’s chat!</p>
    </div>
  )
}

export default Intro
